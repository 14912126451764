<template>
  <div class="container">
    <h1 class="titlePage">Welcome to starline security</h1>
    <span class="text text_green text_center text_italic">Please make a selection</span>
    <div class="thumbnailsTable thumbnailsTable_list">
      <div class="thumbnailsTable thumbnailsTable__item_2" v-for="item in items">
        <router-link :to="item.link" class="thumbnailsTable__imgWrap">
          <img :src="item.img" :alt="item.title" class="thumbnailsTable__banner">
        </router-link>
        <h2>
          <router-link :to="item.link" class="titlePage titlePage_subtitle">{{item.title}}</router-link>
        </h2>
        <p>{{item.text}}</p>
        <div class="thumbnailsTable thumbnailsTable_list thumbnailsTable__listItems">
          <router-link :to="child.link"
                       class="thumbnailsTable__item thumbnailsTable__item_3"
                       v-for="(child, index) in item.children"
                       :key="index"
          >
            <img
              :src="child.img"
              :alt="child.text"
              class="thumbnailsTable__img">
            <span class="thumbnailsTable__text">{{child.text}}</span>
          </router-link>
        </div>
        <router-link :to="item.link" class="thumbnailsTable__btn btn btn_default">Click here</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    data() {
      return {
        items: [
          {
            img: require("./../assets/img/png/consumer.png"),
            link: "/consumer",
            title: "Consumers",
            text: "Get access to our estimate tool to build your own obligation free estimate. Find a professional dealer or contact us now!",
            children: [
              {
                img: require("../assets/img/png/new-estimate.png"),
                text: "Create a New Estimate",
                link: '#',

              },
              {
                img: require("../assets/img/png/estimate.png"),
                text: "Retrive Existing Estimate",
                link: '#',
              },
              {
                img: require("../assets/img/png/dealer.png"),
                text: "Find a Dealer",
                link: '#',
              }
            ]
          },
          {
            img: require("../assets/img/png/wholesale.png"),
            link: "/wholesale",
            title: "Wholesale customer",
            text: "Access our exclusive wholesale ordering portal & quoting tool, track client's orders and much more! Click below to apply or to sign in to your account.",
            children: [
              {
                img: require("../assets/img/png/account.png"),
                text: "Sign in to your Account",
                link: 'login',
              },
              {
                img: require("../assets/img/png/customer.png"),
                text: "Become a Wholesale Customer",
                link: 'registration',
              },
              {
                img: require("../assets/img/png/status.png"),
                text: "Check Application Status",
                link: 'status',
              }
            ]
          }
        ]
      }
    },
    methods: {
      get(key) {
        return !!this.items[key] ? this.items[key] : [];
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../assets/scss/utils/vars';

  .text {
    display: none;
  }

  @media screen and (max-width: $md) {
    .text {
      display: block;
    }
  }
</style>
